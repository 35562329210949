import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';

import Loader from 'components/common/loader';
import SportComponent from './sport';
import VirtualSportComponent from './virtualSport';
import GoldenRaceComponent from './goldenRace';

import { launch, resetProviders } from 'store/actions/dashboard/providers.action';

import LanguageUtils from 'utils/languages';
import { initMirroring } from 'utils/mirror';
import { hasBetsAccess } from 'utils/access';

import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants'

import userInfoType from 'types/userInfo.type';

import Logo from 'assets/images/logo.svg'

/** Provider Frame Component */
const FrameComponent = ({
    launch,
    resetProviders,
    provider,
    isLoading,
    userInfo
}) => {

    const { t } = useTranslation();

    /** Current language */
    const langCode = LanguageUtils.getSelectedLanguage();

    /** Launch iframe */
    useEffect(() => {
        resetProviders();
        setTimeout(() => {
            if (provider && hasBetsAccess(userInfo)) {
                launch(provider, langCode);
            }
        }, 0)
        
    }, [provider])

    /** Init frame mirroring */
    useEffect(() => {
        window.addEventListener("message", event => {
            if (event && event.data) {
                try {
                    const parsed = JSON.parse(event.data);
                    if (parsed) {
                        if (parsed.action === "initialized") {
                            const iframes = document.getElementsByTagName('iframe');
                            let win, iframe;
                            for (let i = 0; i < iframes.length; i++) {
                                win = iframes[i].contentWindow || iframes[i].contentDocument.defaultView;

                                if (win === event.source) {
                                    iframe = iframes[i];
                                    break;
                                }
                            }
                            initMirroring(iframe)
                        }
                    }
                } catch (ex) {
                    return;
                }
            }
        }, false)
    }, [])

    return (
        <div className={'rt--frame-container' + (!userInfo.providers || userInfo.providers.length === 0 || !hasBetsAccess(userInfo) ? " rt--flex rt--align-center rt--justify-center" : "")}>
            {
                hasBetsAccess(userInfo) ?
                    userInfo.providers && userInfo.providers.length > 0 ? (
                        <Fragment>
                            {
                                isLoading && <Loader full={true} />
                            }
                            {
                                provider === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS ?
                                    <VirtualSportComponent /> :
                                    provider === PROJECT_PROVIDER_TYPE.SPORTBOOK ?
                                        <SportComponent /> : 
                                    provider === PROJECT_PROVIDER_TYPE.GOLDEN_RACE ?
                                        <GoldenRaceComponent /> : 
                                        <Result status="warning" title={t('authentication.noTokenFound')} />
                            }
                        </Fragment>
                    ) :
                        <img src={Logo} /> :
                    <img src={Logo} />
            }

        </div>
    )
}

/** FrameComponent propTypes
    * PropTypes
*/
FrameComponent.propTypes = {
    /** Redux state property, is true when provider is lounching */
    isLoading: PropTypes.bool,
    /** Redux state property, current provider */
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    /** Redux action to launch provider */
    launch: PropTypes.func,
    /** Redux action to reset providers */
    resetProviders: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType
}


const mapDispatchToProps = dispatch => (
    {
        launch: (id, langCode) => {
            dispatch(launch(id, langCode))
        },

        resetProviders: () => {
            dispatch(resetProviders())
        }
    }
)

const mapStateToProps = state => {
    return {
        provider: state.providers.provider,
        isLoading: state.providers.isLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrameComponent)